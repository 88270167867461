import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Container } from "./styles";

import api from "../../services/api";
import { setToken, setUser } from "../../services/auth";

export default function LoginForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [erro, setErro] = useState(false);
  const [loading, setLoading] = useState(false);

  const hystory = useHistory();

  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setEmail(event.target.value);
  };
  const onChangePassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setPassword(event.target.value);
  };

  const onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setLoading(true);
      const { data: resp } = await api.post(
        "login",
        {
          email: email,
          password: password,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const token = resp.token;
      setToken(token);
      setUser(token);
      hystory.push("/app");
    } catch (err) {
      setLoading(false);
      console.log(err);
      setErro(true);
    }
  };

  return (
    <Container>
      <img src="./assets/logof.png" alt="logo lukas" />
      <h1>Acesso ao Sistema Lukas</h1>
      <form onSubmit={onSubmit}>
        <input
          type="email"
          value={email}
          placeholder="E-mail"
          onChange={onChangeEmail}
        />
        <input
          type="password"
          value={password}
          placeholder="Senha"
          onChange={onChangePassword}
        />
        <button type="submit">
          Logar
          {loading ? (
            <img src="./assets/spinner_white.svg" alt="spinner" />
          ) : null}
        </button>
        <span className={erro ? "error show" : "error hide"}>
          Erro: Não foi possível efetuar o Login
        </span>
      </form>
    </Container>
  );
}
