import React from "react";

import { Container } from "./card_styles";
import { IVenda } from "../../../Interfaces/Venda";

interface IProps {
  venda: IVenda;
}

export default function CardItem(props: IProps) {
  const {
    num_venda,
    titular,
    contrato,
    telefone,
    cpf,
    data,
    produto,
    servico,
  } = props.venda;
  const total = produto + servico;
  return (
    <Container>
      <div>
        <span>Pedido</span>
        <p>{num_venda}</p>
      </div>
      <div>
        <span>Data/Fatura</span>
        <p>{Intl.DateTimeFormat("pt-Br").format(new Date(data))}</p>
      </div>
      <div>
        <span>Titular</span>
        <p>{titular}</p>
      </div>
      <div>
        <span>CPF</span>
        <p>{cpf}</p>
      </div>
      <div>
        <span>Contrato</span>
        <p>{contrato}</p>
      </div>
      <div>
        <span>Telefone</span>
        <p>{telefone}</p>
      </div>
      <div>
        <span>Produtos</span>
        <p>
          {Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(produto)}
        </p>
      </div>
      <div>
        <span>Serviços</span>
        <p>
          {Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(servico)}
        </p>
      </div>
      <div>
        <span>Total</span>
        <p>
          {Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(total)}
        </p>
      </div>
    </Container>
  );
}
