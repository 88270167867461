import React, { useEffect, useState } from "react";
import moment from "moment";
import api from "../../services/api";
import { getToken } from "../../services/auth";
import { Container, Label } from "./styles";
import ContratosDetalhe from "./Detalhe";

interface Props {
  colorLabel: string;
}

interface IItemApi {
  empresa: string;
}

interface IDataApi {
  _id: IItemApi;
  total: number;
}

export default function WidgetEstoques(props: Props) {
  const { colorLabel } = props;
  const [dados, setDados] = useState<IDataApi[]>([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [dataInicio, setDataInicio] = useState("");
  const [dataFim, setDataFim] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedEmp, setSelectedEmp] = useState("");
  const [chamaApi, setChamaApi] = useState(false);

  useEffect(() => {
    const inicio = new Date();
    inicio.setDate(inicio.getDate() - 30);
    const final = new Date();
    setDataInicio(moment(inicio).format("YYYY-MM-DD"));
    setDataFim(moment(final).format("YYYY-MM-DD"));
    setChamaApi(true);
  }, []);

  useEffect(() => {
    const handlerGetData = async () => {
      try {
        setLoading(true);
        const resp = await api.get("/produtos/estoque", {
          params: {
            inicio: dataInicio,
            fim: dataFim,
          },
          headers: {
            authorization: "Bearer " + getToken(),
          },
        });
        setDados(resp.data.produtos);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        alert(e);
      }
    };

    if (chamaApi) {
      handlerGetData();
      setChamaApi(false);
    }
  }, [chamaApi, dataInicio, dataFim]);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setChamaApi(true);
  };

  const toggleModal = (emp: string, e: any) => {
    if (!modalVisible && emp && emp.length > 0) {
      setSelectedEmp(emp);
      setModalVisible(true);
    }

    if (modalVisible) setModalVisible(false);
  };

  const onChangeInicio = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDataInicio(e.target.value);
  };

  const onChangeFinal = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDataFim(e.target.value);
  };

  return (
    <Container>
      <header>
        <Label color={colorLabel} />
        <p>Estoque (em R$)</p>
      </header>

      {loading ? (
        <div className="img-loading">
          <img src="./assets/spinner.svg" alt="spinner" />
        </div>
      ) : (
          <div className="content">
            <ul className="widget-item">
              {dados.map((dado, index) => (
                <li key={index}
                  value={dado.total}
                  onClick={toggleModal.bind(null, dado._id.empresa)}>
                  <div>
                    <span>Empresa</span>
                    <p>{dado._id.empresa}</p>
                  </div>
                  <div>
                    <span>Total (R$)</span>
                    <p>
                      {Intl.NumberFormat("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      }).format(dado.total)}
                    </p>
                  </div>
                </li>
              )
              )}
            </ul>
          </div>
        )}
      <div className="filtro">
        <form onSubmit={onSubmit}>
          <label>
            Início:
            <input
              type="date"
              name="inicio"
              value={dataInicio}
              onChange={onChangeInicio}
              placeholder="Data Inicial"
            />
          </label>
          <label>
            Final:
            <input
              type="date"
              name="fim"
              value={dataFim}
              onChange={onChangeFinal}
              placeholder="Data Final"
            />
          </label>
          <button type="submit">Consultar</button>
        </form>
      </div>
      <ContratosDetalhe
        visible={modalVisible}
        onOpenClose={toggleModal}
        empresa={selectedEmp}
        inicio={dataInicio}
        fim={dataFim}
      />
    </Container>
  );
}
