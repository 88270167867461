import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  background-image: url("./assets/background.jpg");
  background-color: #333;
  background-repeat: no-repeat;
  background-position: right top;
  background-size: cover;
`;
