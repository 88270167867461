import React from "react";
import GraficoPorStatus from "../GraficoPorStatus";
import { Container } from "./styles";
import GraficoPorPlano from "../GraficoPorPlano";
import GraficoPorFalecido from "../GraficoPorFalecido";
import GraficoPorVenda from "../GraficoPorVenda";
import GraficoPorFluxo from "../GraficoPorFluxo";
import WidgetEstoques from "../WidgetEstoques";

export default function List() {
  return (
    <Container>
      <GraficoPorStatus colorLabel="#2196f3" />
      <GraficoPorPlano colorLabel="#673ab7" />
      <GraficoPorFalecido colorLabel="#f65211" />
      <GraficoPorVenda colorLabel="#109917" />
      <GraficoPorFluxo colorLabel="#107e99" />
      <WidgetEstoques colorLabel="#b13531" />
    </Container>
  );
}
