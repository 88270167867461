import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  padding: 30px;
  height: calc(100% - 60px);
  justify-content: center;

  @media (max-width: 320px) {
    padding: 30px 5px;
  }
`;
