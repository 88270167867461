import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import moment from "moment";
import api from "../../services/api";
import { getToken } from "../../services/auth";
import { Container, Label } from "./styles";
import { chartData, chartOptions } from "../../utils/formatDataGraficoStatus";
import ContratosDetalhe from "../ContratosDetalhe";

interface Props {
  colorLabel: string;
}

export default function GraficoPorStatus(props: Props) {
  const { colorLabel } = props;
  const [dados, setDados] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [dataInicio, setDataInicio] = useState("");
  const [dataFim, setDataFim] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedEmp, setSelectedEmp] = useState("");
  const [chamaApi, setChamaApi] = useState(false);

  useEffect(() => {
    const inicio = new Date();
    inicio.setDate(inicio.getDate() - 30);
    const final = new Date();
    setDataInicio(moment(inicio).format("YYYY-MM-DD"));
    setDataFim(moment(final).format("YYYY-MM-DD"));
    setChamaApi(true);
  }, []);

  useEffect(() => {
    const handlerGetData = async () => {
      try {
        setLoading(true);
        const resp = await api.get("/totalstatus", {
          params: {
            inicio: dataInicio,
            fim: dataFim,
          },
          headers: {
            authorization: "Bearer " + getToken(),
          },
        });
        setDados(resp.data.contratos);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        alert(e);
      }
    };

    if (chamaApi) {
      handlerGetData();
      setChamaApi(false);
    }
  }, [chamaApi, dataInicio, dataFim]);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setChamaApi(true);
  };

  const toggleModal = (e: any) => {
    if (!modalVisible && e && e.length > 0) {
      setSelectedEmp(e[0]._view.label);
      setModalVisible(true);
    }

    if (modalVisible) setModalVisible(false);
  };

  const onChangeInicio = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDataInicio(e.target.value);
  };

  const onChangeFinal = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDataFim(e.target.value);
  };

  return (
    <Container>
      <header>
        <Label color={colorLabel} />
        <p>Gráfico Contratos por Status</p>
      </header>

      {loading ? (
        <div className="img-loading">
          <img src="./assets/spinner.svg" alt="spinner" />
        </div>
      ) : (
        <div className="grafico">
          <Bar
            onElementsClick={toggleModal}
            data={() => chartData(dados)}
            options={chartOptions}
          />
        </div>
      )}
      <div className="filtro">
        <form onSubmit={onSubmit}>
          <label>
            Início:
            <input
              type="date"
              name="inicio"
              value={dataInicio}
              onChange={onChangeInicio}
              placeholder="Data Inicial"
            />
          </label>
          <label>
            Final:
            <input
              type="date"
              name="fim"
              value={dataFim}
              onChange={onChangeFinal}
              placeholder="Data Final"
            />
          </label>
          <button type="submit">Consultar</button>
        </form>
      </div>
      <ContratosDetalhe
        visible={modalVisible}
        onOpenClose={toggleModal}
        empresa={selectedEmp}
        inicio={dataInicio}
        fim={dataFim}
      />
    </Container>
  );
}
