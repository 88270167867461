import React, { useEffect } from "react";
import LoginForm from "../LoginForm";
import { isAuthenticated } from "../../services/auth";
import { Container } from "./styles";
import { useHistory } from "react-router-dom";

export default function PageLogin() {
  const hystory = useHistory();

  useEffect(() => {
    if (isAuthenticated()) {
      hystory.push("/app");
    }
  }, [hystory]);
  return (
    <Container>
      <LoginForm />
    </Container>
  );
}
