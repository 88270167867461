import React from "react";
import { useHistory } from "react-router-dom";
import { MdLockOpen } from "react-icons/md";

import { Container } from "./styles";

function Header() {
  const history = useHistory();

  const onLogout = () => {
    localStorage.clear();
    history.push("/");
  };

  return (
    <Container>
      <h1>LukasSistemas::Dashboard</h1>
      <section>
        <button onClick={onLogout}>
          <MdLockOpen size={24} color="#fff" />
          Sair
        </button>
      </section>
    </Container>
  );
}

export default Header;
