import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background: #fff;
  min-height: 380px;
  border-radius: 5px;
  padding: 15px;

  margin-bottom: 10px;
  box-shadow: 0 1px 4px 0 rgba(192, 208, 230, 0.8);
  border-top: 20px solid rgba(230, 236, 245, 0.4);

  header {
    position: absolute;
    top: -22px;
    left: 15px;
  }

  header p {
    margin-left: 6px;
  }

  p {
    font-weight: 500;
    line-height: 24px;
  }

  .grafico {
    flex: 1;
  }

  .filtro {
    justify-content: space-between;
    justify-content: initial;
    border: 1px solid #eeee;
    border-radius: 4px;
    padding: 5px;
  }

  .filtro button {
    border-radius: 4px;
    background-color: #0277bd;
    padding: 5px;
    color: white;
    transition: opacity 0.2s;
  }
  .filtro button:hover {
    opacity: 0.5;
  }

  .img-loading {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }

  .img-loading img {
    width: 60px;
    height: 60px;
  }

  .widget-item li {
    padding: 4px;
    display: grid;
    grid-template-columns: 1fr 1fr; 
    grid-gap: 20px;
    border-bottom: 1px solid #eeee;

    :hover {
      background: #f3f3f3;
    }
  }
`;

export const Label = styled.span`
  width: 10px;
  height: 10px;
  border-radius: 2px;
  display: inline-block;
  background: ${(props) => props.color};
`;

