import {colors} from './colors';

const mods = ['produto','servico'];

interface IItemData {
  label: string;
  data: number[];
  backgroundColor: string;
}

interface IItemApi {
  empresa: string;
  plano: string;
}

interface IDataApi {
  _id: IItemApi;
  produto: number;
  servico: number;
}

function updateDataSet(datasets: IItemData[], 
    label: string, data: number[], color: string, indexLabel: number, value: number, itemLocalizado?: IItemData) {
  if (datasets.length === 0 || !itemLocalizado) {
    datasets.push({
      label: label,
      data: data,
      backgroundColor: color,
    });
  } else {
    itemLocalizado.data[indexLabel] = value;
    itemLocalizado.backgroundColor = color;
  }  
}


export function chartData(dataApi: IDataApi[]) {
  if (!dataApi) return [];
  const labels: string[] = [];
  const datasets: IItemData[] = [];

  dataApi.forEach((i) => {
    const empresa = i._id.empresa;
    if (labels.length === 0 || !labels.find((label) => label === empresa))
      labels.push(empresa);
  });

  dataApi.forEach((i) => {
    const dataProd: number[] = [];
    const dataServ: number[] = [];
    const empresa = i._id.empresa;
    const indexLabel = labels.findIndex((index) => index === empresa);
    dataProd[indexLabel] = i.produto;
    let label = 'produto';
    let color = colors[mods.findIndex((index) => index === label)];
    const itemLocalizado = datasets.find((index) => index.label === label);
    updateDataSet(datasets, label, dataProd, color, indexLabel, i.produto, itemLocalizado);

    dataServ[indexLabel] = i.servico;
    label = 'servico';
    color = colors[mods.findIndex((index) => index === label)];;
    const itemLocalizadoServ = datasets.find((index) => index.label === label);
    updateDataSet(datasets, label, dataServ, color, indexLabel, i.servico, itemLocalizadoServ);

  });

  return {
    datasets: datasets,
    labels: labels,
  };
}

export const chartOptions = {
  maintainAspectRatio: false,
  scales: {
    xAxes: [
      {
        stacked: true,
      },
    ],
    yAxes: [
      {
        stacked: true,
      },
    ],
  },
};
