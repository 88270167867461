export const isAuthenticated = () => {
  const logado =
    localStorage.getItem("2843e4c9effcb9df433b73cb0a9a057f") &&
    localStorage.getItem("5d2743ea36e5ebcfee0b5fcdda275bc6");
  return logado ? true : false;
};

export const setToken = (token: string) => {
  localStorage.setItem("2843e4c9effcb9df433b73cb0a9a057f", token);
};

export const getToken = () => {
  return localStorage.getItem("2843e4c9effcb9df433b73cb0a9a057f");
};

export const setUser = (token: string) => {
  if (token) {
    try {
      const user = JSON.parse(atob(token.split(".")[1])).user.id;
      if (user) {
        localStorage.setItem("5d2743ea36e5ebcfee0b5fcdda275bc6", user);
      }
    } catch (error) {
      // ignore
    }
  }
};

export const getUser = () => {
  return localStorage.getItem("5d2743ea36e5ebcfee0b5fcdda275bc6");
};
