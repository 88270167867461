import styled from "styled-components";

export const Container = styled.li`
  padding: 4px;
  display: grid;
  grid-template-columns: 40px 180px 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  border-bottom: 1px solid #eeee;

  :hover {
    background: #f3f3f3;
  }
`;
