import {colors} from './colors';

interface IItemData {
  label: string;
  data: number[];
  backgroundColor: string;
}

interface IItemApi {
  empresa: string;
  status: string;
}

interface IDataApi {
  _id: IItemApi;
  count: number;
}

export function chartData(dataApi: IDataApi[]) {
  if (!dataApi) return [];
  const labels: string[] = [];
  const datasets: IItemData[] = [];

  dataApi.forEach((i) => {
    const empresa = i._id.empresa;
    if (labels.length === 0 || !labels.find((label) => label === empresa))
      labels.push(empresa);
  });

  dataApi.forEach((i, index) => {
    const data: number[] = [];
    const empresa = i._id.empresa;

    const status = ["P", "C", "F"];
    const descStatus = ["Pendente", "Cancelado", "Faturado"];
    const dataStatus = i._id.status;

    const label = descStatus[status.findIndex((index) => index === dataStatus)];

    const indexLabel = labels.findIndex((index) => index === empresa);

    data[indexLabel] = i.count;

    const color = colors[status.findIndex((index) => index === dataStatus)];
    const itemLocalizado = datasets.find((i) => i.label === label);

    if (datasets.length === 0 || !itemLocalizado) {
      datasets.push({
        label: label,
        data: data,
        backgroundColor: color,
      });
    } else {
      itemLocalizado.data[indexLabel] = i.count;
      itemLocalizado.backgroundColor = color;
    }
  });

  return {
    datasets: datasets,
    labels: labels,
  };
}

export const chartOptions = {
  maintainAspectRatio: false,
  scales: {
    xAxes: [
      {
        stacked: true,
      },
    ],
    yAxes: [
      {
        stacked: true,
      },
    ],
  },
};
