import styled from "styled-components";

export const Container = styled.div`
  width: 90%;
  height: 100%;
  padding: 0 15px;
  flex: 1;
  max-width: 1280px;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-gap: 20px;

  & + div {
    border-left: 1px solid rgba(0, 0, 0, 0.05);
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 42px;

    h2 {
      font-weight: 500;
      font-size: 16px;
      padding: 0 10px;
    }

    button {
      width: 42px;
      height: 42px;
      border-radius: 18px;
      background: #3b5bfd;
      border: 0;
      cursor: pointer;
    }
  }

  @media (min-width: 768px) {
    grid-template-columns: 1fr;
    max-width: 100%;
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
    max-width: 100%;
  }
`;
