import React from "react";

import { Container } from "./card_styles";
import { IFalecido } from "../../../Interfaces/Falecido";

interface IProps {
  falecido: IFalecido;
}

export default function CardItem(props: IProps) {
  const {
    contrato,
    nome,
    cpf,
    tipo,
    data,
  } = props.falecido;

  return (
    <Container>
      <div>
        <span>Contrato</span>
        <p>{contrato}</p>
      </div>
      <div>
        <span>Nome</span>
        <p>{nome}</p>
      </div>
      <div>
        <span>CPF</span>
        <p>{cpf}</p>
      </div>
      <div>
        <span>Tipo</span>
        <p>{tipo}</p>
      </div>
      <div>
        <span>Data/Falec.</span>
        <p>{Intl.DateTimeFormat("pt-Br").format(new Date(data))}</p>
      </div>
    </Container>
  );
}
