import styled from "styled-components";

export const Container = styled.div`
  padding: 20px;
  width: 380px;
  height: 380px;
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  box-shadow: 0 3px 6px 0 #999;

  img {
    width: 48px;
    height: 48px;
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  form input {
    height: 40px;
    margin-bottom: 20px;
    padding: 5px;
  }

  form button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: #1976d2;
    line-height: 24px;
    color: #fff;
    font-size: 1.2rem;
    border-radius: 5px;
    padding: 10px;
    transition: 0.2s;
  }
  form button:hover {
    background-color: #115293;
  }
  form button img {
    height: 24px;
  }

  h1 {
    font-size: 1.5rem;
    color: #2196f3;
    margin-bottom: 20px;
  }

  .error {
    color: #fff;
    font-weight: bold;
    border-radius: 5px;
    background: #ef5350;
    margin-top: 10px;
    padding: 10px;
    text-align: center;
  }

  .show {
    display: null;
  }

  .hide {
    display: none;
  }
`;
