import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { Container } from "./styles";
import api from "../../../services/api";
import { getToken } from "../../../services/auth";
import { IVenda } from "../../../Interfaces/Venda";
import CardItem from "./card";
import ReactPaginate from "react-paginate";

interface Props {
  visible: boolean;
  onOpenClose: Function;
  empresa: string;
  inicio: string;
  fim: string;
}

interface IPageSelect {
  selected: number;
}

interface ITotais {
  produtos: number;
  servicos: number;
  total: number;
}

export default function VendaDetalhe(props: Props) {
  const { visible, onOpenClose, empresa, inicio, fim } = props;
  const [dataInicio, setDataInicio] = useState("");
  const [dataFim, setDataFim] = useState("");
  const [dados, setDados] = useState<IVenda[]>([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [totais, setTotais] = useState<ITotais[]>([]);

  useEffect(() => {
    if (!visible) {
      setDados([]);
    }
  }, [visible]);

  useEffect(() => {
    Modal.setAppElement("body");

    if (inicio && fim) {
      setDataInicio(new Date(inicio).toISOString().split("T")[0]);
      setDataFim(new Date(fim).toISOString().split("T")[0]);
    }
  }, [inicio, fim]);

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleGetData({ selected: 0 });
  };

  const handleGetData = async (selectedPage: IPageSelect) => {
    try {
      setLoading(true);
      const resp = await api.get(
        `/vendas/list?page=${selectedPage.selected + 1}`,
        {
          params: {
            inicio: dataInicio,
            fim: dataFim,
            empresa: empresa,
          },
          headers: {
            authorization: "Bearer " + getToken(),
          },
        }
      );
      setDados(resp.data.vendas);
      setTotais(resp.data.totais);
      setPageCount(
        resp.data.registers ? Math.ceil(resp.data.registers / 20) : 1
      );
      setLoading(false);
    } catch (e) {
      setLoading(false);
      alert(e);
    }
  };

  const onChangeInicio = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDataInicio(e.target.value);
  };

  const onChangeFinal = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDataFim(e.target.value);
  };

  return (
    <Modal isOpen={visible} contentLabel="Example Modal">
      <Container>
        <header>
          <h1>{empresa}</h1>
        </header>
        <div className="filtro">
          <h2>Filtro</h2>
          <form onSubmit={onSubmit}>
            <label>
              Início:
              <input
                type="date"
                name="inicio"
                value={dataInicio}
                onChange={onChangeInicio}
                placeholder="Data Inicial"
              />
            </label>
            <label>
              Final:
              <input
                type="date"
                name="fim"
                value={dataFim}
                onChange={onChangeFinal}
                placeholder="Data Final"
              />
            </label>
            <button type="submit">Consultar</button>
          </form>
          <button className="botalmodal" onClick={() => onOpenClose()}>
            X
          </button>
        </div>
        {loading ? (
          <div className="img-loading">
            <img src="./assets/spinner.svg" alt="spinner" />
          </div>
        ) : (
          <div className="content">
            <ul>
              {dados.map((dado) => (
                <CardItem key={dado._id} venda={dado} />
              ))}
            </ul>
          </div>
        )}
        <div className="paginate">
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={20}
            onPageChange={handleGetData}
            containerClassName={"pagination"}
            activeClassName={"active"}
          />
        </div>
        <footer>
        {totais.map((total, index) => {
            return (
              <div key={index}>
                <div>
                  <span>Produtos: </span>
                  <p>
                    {Intl.NumberFormat("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    }).format(total.produtos)}
                  </p>
                </div>
                <div>
                <span>Serviços: </span>
                <p>
                  {Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(total.servicos)}
                </p>
              </div>
              <div>
                <span>Total: </span>
                <p>
                  {Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(total.total)}
                </p>
              </div>
              </div>
             );
            })}
          {/*  */}
        </footer>
      </Container>
    </Modal>
  );
}
