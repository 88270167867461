interface IItemData {
  label: string[];
  data: number[];
  backgroundColor: string[];
}

interface IItemApi {
  empresa: string;
  nome: string;
  cpf: string;
  tipo: string;
  data: string;
}

interface IDataApi {
  empresa: string;
  count: number;
}

export function chartData(dataApi: IDataApi[]) {
  if (!dataApi) return [];
  const labels: string[] = [];
  const dataset: IItemData[] = [];
  const values: number[] = [];
  const colors: string[] = [];

  const dynamicColors = function() {
    var r = Math.floor(Math.random() * 255);
    var g = Math.floor(Math.random() * 255);
    var b = Math.floor(Math.random() * 255);
    return "rgb(" + r + "," + g + "," + b + ")";
  };

  dataApi.forEach((i) => {
    const empresa = i.empresa;
    colors.push(dynamicColors());
    labels.push(empresa);
    values.push(i.count);
  });
  dataset.push({
    label: labels,
    data: values,
    backgroundColor: colors,
  });
return {
    datasets: dataset,
    labels: labels,
  };
}

export const chartOptions = {
  maintainAspectRatio: false,
  scales: {
    xAxes: [
      {
        stacked: true,
      },
    ],
    yAxes: [
      {
        stacked: true,
      },
    ],
  },
};
