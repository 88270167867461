import styled from "styled-components";

export const Container = styled.div`
  height: 55px;
  padding: 0 30px;
  background: #2196f3;
  color: #fff;

  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 3px 4px -1px #999;

  section button {
    display: flex;
    border-radius: 5px;
    align-items: center;
    background: #2196f3;
    color: white;
    font-weight: bold;
  }

  section button:hover {
    background: #1e88e5;
    padding: 6px;
  }

  @media (max-width: 320px) {
    font-size: 0.5rem;
  }
`;
