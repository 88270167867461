import React from "react";

import { Container } from "./styles";
import { IContrato } from "../../Interfaces/Contrato";

interface IProps {
  contrato: IContrato;
}

export default function CardItem(props: IProps) {
  const {
    num_contrato,
    tit_nome,
    tit_cpf,
    tit_fone,
    inicio,
    termino,
    plano,
    valor_parc,
    status,
  } = props.contrato;

  return (
    <Container>
      <div>
        <span>Contrato</span>
        <p>{num_contrato}</p>
      </div>
      <div>
        <span>Titular</span>
        <p>{tit_nome}</p>
      </div>
      <div>
        <span>CPF</span>
        <p>{tit_cpf}</p>
      </div>
      <div>
        <span>Telefone</span>
        <p>{tit_fone}</p>
      </div>
      <div>
        <span>Início</span>
        <p>{Intl.DateTimeFormat("pt-Br").format(new Date(inicio))}</p>
      </div>
      <div>
        <span>Término</span>
        <p>{Intl.DateTimeFormat("pt-Br").format(new Date(termino))}</p>
      </div>
      <div>
        <span>Plano</span>
        <p>{plano}</p>
      </div>
      <div>
        <span>Parcela</span>
        <p>
          {Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(valor_parc)}
        </p>
      </div>
      <div>
        <span>Status</span>
        <p>{status}</p>
      </div>
    </Container>
  );
}
