import React from "react";

import { Container } from "./card_styles";
import { IProduto } from "../../../Interfaces/Produto";

interface IProps {
  produto: IProduto;
}

export default function CardItem(props: IProps) {
  const {
    cod_interno,
    nome,
    saldo,
    preco,
    dt_compra,
    dt_venda,
  } = props.produto;

  return (
    <Container>
      <div>
        <span>Cód/Interno</span>
        <p>{cod_interno}</p>
      </div>
      <div>
        <span>Nome</span>
        <p>{nome}</p>
      </div>
      <div>
        <span>Saldo</span>
        <p>
          {saldo}
        </p>
      </div>
      <div>
        <span>Preço</span>
        <p>
          {Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(preco)}
        </p>
      </div>
      <div>
        <span>Total</span>
        <p>
          {Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(saldo * preco)}
        </p>
      </div>
      <div>
        <span>Data/Compra</span>
        <p>{
          (dt_compra
            ? Intl.DateTimeFormat("pt-Br").format(new Date(dt_compra))
            : "")
        }</p>
      </div>
      <div>
        <span>Data/Venda</span>
        <p>{
          (dt_venda
            ? Intl.DateTimeFormat("pt-Br").format(new Date(dt_venda))
            : "")
        }</p>
      </div>
    </Container>
  );
}
