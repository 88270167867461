import {colors} from './colors';

const mods = ['receber', 'pagar', 'saldo'];

interface IItemData {
  label: string;
  data: number[];
  backgroundColor: string;
}

interface IItemApi {
  empresa: string;
}

interface IDataApi {
  _id: IItemApi;
  receber: number;
  pagar: number;
  saldo: number;
}

function updateDataSet(datasets: IItemData[], 
    label: string, data: number[], color: string, indexLabel: number, value: number, itemLocalizado?: IItemData) {
  if (datasets.length === 0 || !itemLocalizado) {
    datasets.push({
      label: label,
      data: data,
      backgroundColor: color,
    });
  } else {
    itemLocalizado.data[indexLabel] = value;
    itemLocalizado.backgroundColor = color;
  }  
}

export function chartData(dataApi: IDataApi[]) {
  if (!dataApi) return [];
  const labels: string[] = [];
  const datasets: IItemData[] = [];

  dataApi.forEach((i) => {
    const empresa = i._id.empresa;
    if (labels.length === 0 || !labels.find((label) => label === empresa))
      labels.push(empresa);
  });

  dataApi.forEach((i) => {
    const dataReceb: number[] = [];
    const dataPagar: number[] = [];
    const dataSaldo: number[] = [];
    const empresa = i._id.empresa;
    const indexLabel = labels.findIndex((index) => index === empresa);

    dataReceb[indexLabel] = i.receber;
    let label = 'receber';
    let color = colors[mods.findIndex((index) => index === label)];
    const itemReceb = datasets.find((index) => index.label === label);
    updateDataSet(datasets, label, dataReceb, color, indexLabel, i.receber, itemReceb);

    dataPagar[indexLabel] = i.pagar;
    label = 'pagar';
    color = colors[mods.findIndex((index) => index === label)];;
    const itemPagar = datasets.find((index) => index.label === label);
    updateDataSet(datasets, label, dataPagar, color, indexLabel, i.pagar, itemPagar);

    dataSaldo[indexLabel] = i.saldo;
    label = 'saldo';
    color = colors[mods.findIndex((index) => index === label)];;
    const itemSaldo = datasets.find((index) => index.label === label);
    updateDataSet(datasets, label, dataSaldo, color, indexLabel, i.saldo, itemSaldo);

  });

  return {
    datasets: datasets,
    labels: labels,
  };
}

export const chartOptions = {
  maintainAspectRatio: false,
  scales: {
    xAxes: [
      {
        stacked: true,
      },
    ],
    yAxes: [
      {
        stacked: true,
      },
    ],
  },
};
