import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  header {
    justify-content: space-between;
    justify-content: initial;
    padding: 4px;
    color: white;
    background: #2196f3;
    border-radius: 4px;
    margin-bottom: 5px;
  }
  header h1 {
    font-size: 14px;
    margin: 0;
    padding: 0;
  }

  .filtro {
    justify-content: space-between;
    justify-content: initial;
    border: 1px solid #eeee;
    border-radius: 4px;
    margin-bottom: 5px;
  }
  .filtro h2 {
    margin-left: 5px;
    margin-top: 5px;
  }
  form {
    display: flex;
    align-items: center;
    padding: 5px;
  }
  form input {
    margin-left: 5px;
    margin-right: 5px;
    padding: 3px;
    border: 1px solid #eee;
    border-radius: 2px;
  }
  form button {
    border-radius: 4px;
    background-color: #0277bd;
    padding: 5px;
    color: white;
    transition: opacity 0.2s;
  }
  form button:hover {
    opacity: 0.5;
  }

  .botalmodal {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background: #ff6f00;
    border: 0;
    color: white;
    cursor: pointer;
  }

  .content {
    border-radius: 4px;
    min-height: 380px;
    flex: 1;
    margin-bottom: 5px;
    overflow-y: scroll;
  }

  .paginate {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
  }

  footer {
    display: flex;
    flex-direction: row;
    border: 1px solid #eeeeee;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    height: 30px;
  }

  footer div {
    display: flex;
    padding-top: 10px;
  }
  footer span {
    color: #2196f3;
    margin-left: 10px;
  }

  footer p {
    color: #ff6f00;
    font-weight: bold;
    margin-left: 10px;
  }

  .img-loading {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }

  .img-loading img {
    width: 60px;
    height: 60px;
  }
`;
