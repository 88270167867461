import React from "react";

import { Container } from "./card_styles";
import { IFluxo } from "../../../Interfaces/Fluxo";

interface IProps {
  fluxo: IFluxo;
}

export default function CardItem(props: IProps) {
  const {
    data,
    receber,
    pagar,
    saldo,
    acumulado,
  } = props.fluxo;
 
  return (
    <Container>
      <div>
        <span>Data</span>
        <p>{Intl.DateTimeFormat("pt-Br").format(new Date(data))}</p>
      </div>
      <div>
        <span>Receber</span>
        <p>
          {Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(receber)}
        </p>
      </div>
      <div>
        <span>Pagar</span>
        <p>
          {Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(pagar)}
        </p>
      </div>
      <div>
        <span>Saldo</span>
        <p>
          {Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(saldo)}
        </p>
      </div>
      <div>
        <span>Acumulado</span>
        <p>
          {Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(acumulado)}
        </p>
      </div>
    </Container>
  );
}
