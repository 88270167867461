import React from "react";
import { Container } from "./styles";
import List from "../List";
import Header from "../Header";

export default function index() {
  return (
    <>
      <Header />

      <Container>
        <List />
      </Container>
    </>
  );
}
